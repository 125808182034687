.ossh {
  .spinner-border {
    color: white;
    display: block !important;
  }
  &-link {
    margin-bottom: 25px;
    a {
      text-decoration: underline !important;
      font-weight: 700;
      color: $shopeeOrange !important;
      font-size: 16px;

      &:hover {
        color: $orange-light;
      }
    }
  }
  &__gen-report {
    padding-right: 30px;
  }
  &__Card {
    background: $white;
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 40px;

    .heading {
      margin-bottom: 30px;
      display: flex;

      .title {
        margin-right: 60px;
      }
    }

    &--GeneratedSummary {
      .mb-5 p {
        display: flex;
        justify-content: space-between;
      }
      .mb-2 {
        &:not(.linkage-check), p {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .actionButton {
    display: flex;
    align-items: center;
    padding: 0px 10px;

    &__Container {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &--delete {
      margin-right: 20px;
    }
    .btn-transparent {
      text-decoration: underline;
      color: $shopeeOrange;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 0px;
      img {
        margin-right: 5px;
      }
      &:active {
        border: solid 1px transparent;
      }
    }
    .btn {
      &-light {
        &--orange {
          color: $shopeeOrange;
        }
      }
    }
  }
  .requiredField{
    color: $red;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .requiredFieldNote{
    font-size: 16px;
    font-style: italic;
    margin-bottom: 20px;
    color: $grey;
  }
}

@import './osshLinkageCheck';
  