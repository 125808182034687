.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .logo-container {
    display: flex;
    color: $shopeeOrange;
    align-items: center;

    .logo {
      margin-right: 10px;
    }
  }
}
.nav{
      &-tabs{
        margin-bottom: 50px;
        border-bottom: none;
      }
      &-link {
        font-size: 16px;
        color: $grey;
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        margin-right: 20px;
        padding: 0px 0px 20px 0px;
        &:disabled{
          color: $grey !important;
        }
        .active{
          font-weight: 700;
        }

      }
}