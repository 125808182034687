.casesModal {
    table {
        td {
            white-space: pre-line;
        }
    }
    .modal-footer {
        border: none;
    }
    &__detailed{
        color: $grey;
        &--data{
            font-size: 16px;
            background: transparent !important;
            text-align: left !important;
            padding: 10px !important;
            color: $grey !important;
        }
    }
    &__general {
        text-align: left;
        justify-content: flex-start;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border: 0.5px solid #EBEBEB;
        border-radius: 10px;
        padding: 15px;
      
        td{
            padding: 0px !important;
            border-bottom: none !important;
        }
        &:hover {
            background: $orange-light;
            cursor: pointer;
        }
    }
    &__details{
        background: transparent !important;
        border: none !important;
        padding: 0px !important;
        text-decoration: underline !important;
        font-weight: 600 !important;
        font-size: 18px;
        color: $shopeeOrange !important;
    }
}