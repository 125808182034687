.remarksModal {
  .modal-footer {
    .commentBox{
      margin-bottom: 20px;
      height: 100px;
    }
  }
  &__content {
    margin-top: 15px;
    h3 {
      font-size: 1.0rem;
    }
    h4 {
      font-size: .9rem;
      font-weight: normal;
      text-decoration: underline;
    }
    li {
      font-size: .8rem;
      padding-left: 0px;
      margin-left: 1.2rem;
    }
    ul {
      padding-left: 0px;
    }
    & > ul > li {
      padding: 2px 20px;
    }
  }
  &__history {
    &__item {
      &:not(&:first-child) {
        margin-top: 15px;
      }
    }
  }
}