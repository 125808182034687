.form {
  &-label {
    color: $grey;
    margin-bottom: 0px;
  }
  &-control,
  &-select {
    height: 50px;
    border: solid $grey-border 0.5px;
    border-radius: 10px;
    color: $grey-dark;
  }
  &-check {
    margin-bottom: 0px;
  }
  &-check-input {
    &:focus {
      box-shadow: none;
    }
  }
  &-check-input {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    &:checked {
      background-color: $shopeeOrange;
      border-color: $shopeeOrange;
    }
    &:disabled{
      background-color: $grey;
    }
  }
  &-check-inline {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
}

@media(min-width: 1510px) {
.input-group{  
    min-width: 250px;
}
}

