$black: #4d4d4d;
$blue: #1479e0;
$blue-bg: rgba(91, 147, 255, 0.2);
$grey-border: #e2e8f0;
$grey:#B0AEAE;
$grey-dark: #949494;
$grey-input: #f7f7f8;
$grey-input-dark: #fafafb;
$grey: #b0aeae;
$green: #48bb78;
$indigo: #13158f;
$orange-light: rgba(238, 77, 45, 0.2);
$orange: #f26221;
$red: #c53b3b;
$red-bg: rgba(197, 59, 59, 0.1);
$shopeeBlue: #113366;
$shopeeOrange: #ed4d2d;
$white: #ffffff;
$yellow: #ffba34;

$logo-size-s: 36px;
$logo-size-m: 53px;

$icon-size-m: 20px;

$modal-width: 695px;
$button-min-width: 100px;

$border-radius-s: 10px;
@mixin wrap {
  max-width: 1386px;
  margin-left: auto;
  margin-right: auto;
}

@mixin flex-cAlign-cJustify {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-cAlign-cBaseline {
  display: flex;
  align-items: baseline;
}

@mixin flex-cJustify-cAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-Start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex-spaceBetween {
  display: flex;
  justify-content: space-between;
}
@mixin flex-Column{
  flex-direction: column;
}
@mixin flex-Row{
  flex-direction: row;
}
//Utilities
.mr-2{
  margin-right: 10px;
}