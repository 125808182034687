.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  &-dialog {
    min-width: $modal-width;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

  }
  &-content {
    border-radius: 30px;
    
  }
  &-header {
    border-bottom: 0px;
    padding: 30px;
    display: flex;
    align-items: flex-start;
  }
  &-body {
    padding: 0px 30px;
    max-height: 440px;
    overflow-y: scroll;

    .comments {
      &__Container {
        height: 280px;
        color: $grey-dark;
        margin-bottom: 35px;
        overflow-y: auto;

        .description {
          margin-bottom: 10px;
          color: $black;
        }
        .comment {
          padding: 20px 0px;
          border-bottom: solid $grey-border 0.5px;
          margin-right: 20px;
        }
      }
    }
    .commentBox {
      margin-bottom: 20px;
    }
  }
  &-footer {
    border-top: 0px;
    padding: 0px 30px 30px 30px;
  }
  a {
    text-decoration: underline;
    color: $shopeeOrange;
    cursor: pointer;
  }
  .assignedCases {
    border: solid $grey-border 0.5px;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;

    &:hover {
      background-color: $orange-light;
      border: solid $orange-light 0.5px;
    }
  }
}
