.fraudPortal {
  max-height: 100vh;
  overflow-y: auto;
  
  &__content{
    border-radius: 30px;
    padding: 30px;
    background-color: $white;
  }

  section:empty {
    display: none;
  }
  div:empty {
    display: none;
    padding: 0px;
  }
}

