.decisions {
  &__Container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
  }

  .decision {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-weight: 400;
    img {
      margin-right: 5px;
    }
      &--pending {
        color: $yellow;
      }
      &--non-fraud {
        color: $green;
      }
      &--fraud {
        color: $red;
      }
      &--possible-fraud {
        color: $orange;
      }
      &--monitoring {
        color: $blue;
      }
      &--flagging {
        color: $indigo;
      }
    }
}
.pending {
  color: $yellow;
  &:before{
    content: "\00a0  \00a0 \00a0";
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/pending.png');
    background-position-y: center;
  }
}
.not-fraud {
  color: $green;
  &:before{
    content: "\00a0  \00a0 \00a0";
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/non-fraud.png');
    background-position-y: center;
  }
}
.fraud {
  color: $red;
  &:before{
    content: "\00a0  \00a0 \00a0";
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/fraud.png');
    background-position-y: center;
  }
}

.for-monitoring {
  color: $blue;
  &:before{
    content: "\00a0  \00a0 \00a0";
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/monitoring.png');
    background-position-y: center;
  }
}
.for-flagging {
  color: $indigo;
  &:before{
    content: "\00a0  \00a0 \00a0";
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/flagging.png');
    background-position-y: center;
  }
}