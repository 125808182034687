.btn {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  
  &-secondary{
    background: $orange-light;
    color: $shopeeOrange;
    border: transparent;

    &:hover{
    background: $shopeeOrange;
    color: $white;
    border-color: $shopeeOrange;
    }
  }
  &-light {
    background: transparent;
    border: transparent;
    color: $grey;
    font-weight: 700;
    img {
      margin-right: 5px;
    }
    &:hover {
      background-color: transparent !important;
      color: $grey;
    }
  }
  &-light.btn--orange{
    color: $shopeeOrange;
    text-decoration: underline;
    &:hover{
      color: $shopeeOrange !important;
    }
  }
  &-primary {
    background: $shopeeOrange;
    color: $white;
    border-color: $shopeeOrange;
    &:disabled{
      background-color: $grey;
      color: $white;
      border-color: transparent;
    }

    &:hover {
      background-color: $orange-light !important;
      color: $shopeeOrange !important;
      border-color: transparent !important;
    }
    &:active,
    &:focus{
      background-color: $shopeeOrange;
    }
  }
}
button:not(.btn-light, .btn-close, .nav-link) {
  min-width: 142px;
  height: 50px;
}
.dropdown {
  .dropdown-toggle{
    overflow:hidden;
    line-height: 2rem;
    max-height: 8rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    width: 180px;
  }
  .dropdown-toggle.btn.btn-Secondary {
    background-color: $white !important;
    border: solid $shopeeOrange 0.5px !important;
    color: $shopeeOrange !important;
    height: 50px;
    padding: 0px 40px;
  }
  .dropdown-toggle.btn.btn-Primary {
    background-color: $orange-light !important;
    border: solid $shopeeOrange 0.5px !important;
    color: $shopeeOrange !important;
    height: 50px;
    padding: 0px 40px;
  }
  .dropdown-toggle.btn.btn-Light {
    background-color: transparent !important;
    border: solid transparent 0.5px !important;
    color: $grey !important;
    height: 50px;
    padding: 0px 40px;
  }

  &-item {
    padding: 10px 20px;
  }
}
