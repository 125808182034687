$shopeeOrange: #ED4D2D;
$grey: #9C9CB6;
$shopeeBlue: #113366;
@import "./scss/mixins";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;900");
@import "~bootstrap/scss/bootstrap";
body {
  background: #f7fafc;
  color: $black;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  padding: 30px;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: $grey;
  }
  .logo {
    width: $logo-size-m;
    height: $logo-size-m;
    img {
      width: 100%;
    }
  }
  label {
    color: $grey;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 0px;
    font-weight: 600;
    color: $shopeeOrange;
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  a {
    text-decoration: none;
    color: $shopeeOrange;
    &:hover {
      color: $shopeeOrange;
    }
  }
}
[aria-hidden="true"] {
  display: none;
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: 1px solid $grey-border !important;
  border-radius: 4px !important;
  margin-top: 0px;
}
input[type="checkbox"]:checked {
  accent-color: $shopeeOrange;
  background-color: $shopeeOrange;
}
.checkbox {
  &__Container {
    margin-right: 10px;
    display: flex;
    align-items: center;
    .form-check-input {
      margin-right: 5px;
    }
  }
}
div {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: #ebebeb;
  }
}
@mixin wrap {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

@mixin flex-cAlign-cJustify {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-cAlign {
  display: flex;
  align-items: center;
}

@mixin flex-cJustify {
  display: flex;
  justify-content: center;
}

// override bootstrap styles
@mixin shopeeOrangeButton {
    background: $shopeeOrange !important;
    border-color: $shopeeOrange !important;
    &:hover, &:focus:active {
        background: $shopeeOrange !important;
        background-color: $shopeeOrange !important;
    }
    &:focus:active {
        box-shadow: 0 0 0 0.25rem #ed4d2d1f !important;
    }
}
@import "./scss/navbar";
@import "./scss/buttons";
@import "./scss/CasesModal.scss";

@import "./scss/Dashboard.scss";



@import "./scss/ossh";

@import "./scss/Header.scss";

@import "./scss/Searchbar.scss";
@import "./scss/RemarksModal.scss";
@import "./scss/fraudPortal";

@import "./scss/decisionLogo";
@import "./scss/form";
@import "./scss/filters";
@import "./scss/decisions";
@import "./scss/dailytm";
@import "./scss/Table.scss";
@import "./scss/modal";

