.dailyTM {
  .actions {
    text-decoration: underline !important;
    .btn {
      text-decoration: underline;
    }
  }
  .decisions {
    display: flex;
    justify-content: center;
    .decision {
      margin-right: 0px;
    }
  }
}
