.header {
    @include wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    svg {
        width: 185px;
        height: auto;
        path {
            fill: $shopeeOrange;
        }
    }
    h1 {
        font-size: 28px;
    }
}