.filters {
  width: 100%;
  margin-bottom: 30px;
  &__Container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .dropdown-btn{
      overflow: hidden;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      width: 160px;
      font-size: 12px;
      word-break: break-word;
  }
    }
  }

