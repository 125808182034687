.decisionLogo {
  & {
    text-transform: capitalize;
  }
  &-- {
    // default
    color: #48bb78;
    @extend .decisionLogo;
  }
  &--not_fraud {
    color: #48bb78;
    @extend .decisionLogo;
  }
  &--pending {
    color: #ffba34;
    @extend .decisionLogo;
  }
  &--confirmed_fraud {
    color: #c53b3b;
    @extend .decisionLogo;
  }
  &--possible-fraud {
    color: #f26221;
    @extend .decisionLogo;
  }
  &--for_monitoring {
    color: #1479e0;
    @extend .decisionLogo;
  }
  &--for_flagging {
    color: #13158f;
    @extend .decisionLogo;
  }
}