.table {
  position: relative;
  min-height: 200px;
  
   > :not(:first-child){
    border-top: none;
  }

  &__loader {
    z-index: -1;
    opacity: 0;
    transition: opacity ease .1s;
    height: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    .spinner-border {
      margin-top: 50px;
      position: sticky;
      top: 0;
      &:empty{
        display: block !important;
      }
    }
    &.loading {
      height: 100%;
      opacity: 1;
      z-index: 1;
    }
  }
  tr th {
    font-weight: 500;
    color: $black;
    text-align: center;
    background-color: #f7fafc;
    padding: 15px 20px;
    font-size: 16px;

    &:last-child {
      text-align: left;
    }
  }
  tr td {
    text-align: center;
  }
  td{
    padding: 20px 0px;
  }

  &--ossh {
    tr th {
      &:last-child {
        text-align: center;
      }
    }
    tr td {
      vertical-align: middle;
      &:first-child{
        padding-right: 20px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

}
