.dashboard {
    margin-top: 29px;
    &__filter {
        @include flex-Column;
        &__search{
            @include flex-Column;
            margin-right: 10px;
            margin-bottom: 20px;
        }
        &__date {
            position: relative;
            margin-right: 10px;
            margin-bottom: 20px;
            span {
                margin: 0px 10px;
            }
            small {
                position: absolute;
                top: -29px;
            }
        }
        &__dropdowns {
            @include flex-Start;
            @include flex-Row;
            @include flex-spaceBetween;
            margin-bottom: 20px;
            button {
                @include shopeeOrangeButton;
                margin-right: 15px;
            }
            .show > .btn-primary.dropdown-toggle {
                background: $shopeeOrange !important;
                background-color: $shopeeOrange !important;
                color: $white !important;
                box-shadow: 0 0 0 0.25rem #ed4d2d1f !important;
            }
        }
        &__decisions {
            .form-check {
                margin-right: 15px;
            }
        }
        &__container{
            @include flex-Start;
            justify-content: flex-start;
            @include flex-Row;
            width: 100%;
            align-items: flex-end;
            margin-bottom: 30px;
        }
    }
    &__table {
        margin-top: 30px;
        .td-remarks {
            padding: 8px 15px;
        }
        .td-data {
            min-width: 170px;
            text-align: left;
            ul{
                padding-left: 0px;
            }
            a { 
                margin-right : 10px;
                font-weight: 700;
                font-size: 14px;
                color: $grey;
                text-decoration: underline;
                img{
                    margin-right: 8px;
                }
            }
            li { margin-bottom: 5px; }
            ul li { list-style: none; }
        }
        .td-decision {
            .btn-light{
                margin: 0 auto;
            }
            .show > .btn-success.dropdown-toggle:focus, .btn-success:focus {
                box-shadow: 0 0 0 0.25rem  #11336642;
            }

            .dropstart {
                button { text-transform: capitalize; }
            }
            [x-placement="left-start"] {
                inset: -41px 0px auto auto !important;
            }
        }
        .nav-tabs {
            .nav-link:not(.active, .disabled) { 
                color: grey; 
            }
            .nav-link.active {
                color: $shopeeOrange; 
                border-bottom: $shopeeOrange solid;
                border-top: none;
                border-left: none;
                border-right: none;
            }
            button[disabled] {
                cursor: not-allowed;
            }
        }
    }

    @include wrap;

    @media(max-width: 1216px) {

        &__filter {
            flex-wrap: wrap;
            justify-content: flex-start;
            & > div {
                margin: 10px 5px;
                flex-wrap: wrap;
            }
        }
    }
    
}


